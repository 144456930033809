.claimsMappingContainer {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-size: 14px;

  .horizontalHeadingValues {
    display: flex;
    width: 100%;

    .container {
      flex: 1;
      margin: 0px;
    }
  }
  .titleContainer {
    width: 150px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mapWorkgroup {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .adGroup {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .mapRole {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .dropDownWidth {
    width: 150px;
  }
  .adGroupValue {
    width: 150px;
  }
}
